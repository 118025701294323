import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ValuesMapper } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import { defaultDateFormat } from 'shared/constants';
import { IconEdit, IconSearch } from 'shared/Icons';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import { saveSortingAndFiltering } from 'industry/actions';
import { getCurrentUserSuccess } from 'user/actions';
import api from 'helpers/api';

import { getWorkforceWorkerCategory } from 'shared/Api';
import { getWorkersList, getPaginatedWorkersList, getAllDepartments, getPartners, getRelatedWorkerInfo, getRelatedInfo } from '../../../actions';

import WorkerModalForm from './WorkerModalForm';

const WorkersList = ({
  t,
  can,
  history,
  company,
  companyId,
  department,
  locationId,
  currentUser,
  setCurrentUser,
  customDepartment,
  managerDepartments,
}) => {
  const debounceTimeoutRef = useRef(null);

  const activeFilterOptions = [
    { id: 'active', name: t('page_content.human_resources.workers.activity_option_active') },
    { id: 'inactive', name: t('page_content.human_resources.workers.activity_option_inactive') }];
  const searchByOptions = [
    { id: 'name', name: t('page_content.human_resources.workers.search_by_option_name') },
    { id: 'email', name: t('page_content.human_resources.workers.search_by_option_email') },
    { id: 'private_email', name: t('page_content.human_resources.workers.search_by_option_private_email') },
    { id: 'external_id', name: t('page_content.human_resources.workers.search_by_external_id') },
  ];

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState({
    data: [],
    count: null,
    next: null,
    previous: null,
    isLoading: true,
  });

  const latestStateRef = useRef({ extraFilters: {}, filters: {}, query: '' });
  const sortingAndFiltering = get(currentUser, 'data.HR', null);

  const [query, setQuery] = useState(!get(sortingAndFiltering, 'query', null) ? null : get(sortingAndFiltering, 'query'));
  const [filters, setFilters] = useState({
    activityFilter: get(sortingAndFiltering, 'filters.activityFilter', activeFilterOptions[0]),
    departmentFilter: get(sortingAndFiltering, 'filters.departmentFilter', !can(SECTIONS.DEPARTMENT_MANAGER, ACTIONS.VIEW) ? managerDepartments[0] : department),
    companiesFilter: get(sortingAndFiltering, 'filters.companiesFilter', ''),
    selectedSearchBy: get(sortingAndFiltering, 'filters.selectedSearchBy', searchByOptions[0]),
    selectedAscDesc: get(sortingAndFiltering, 'filters.selectedAscDesc', 'desc'),
    selectedSort: get(sortingAndFiltering, 'filters.selectedSort', 'active_from'),
    workPermit: get(sortingAndFiltering, 'filters.workPermit', null),
    workerCategory: get(sortingAndFiltering, 'filters.workerCategory', null),
  });
  const [extraFilters, setExtraFilters] = useState({
    relatedInfo: get(sortingAndFiltering, 'extraFilters.relatedInfo', null),
    relatedInfoValue: get(sortingAndFiltering, 'extraFilters.relatedInfoValue', null),
    relatedInfoValueQuery: get(sortingAndFiltering, 'extraFilters.relatedInfoValueQuery', ''),
  });
  const [relatedInfo, setRelatedInfo] = useState([]);
  const [relatedInfoValues, setRelatedInfoValues] = useState([]);
  const [isLoadingRelatedInfoValues, setIsLoadingRelatedInfoValues] = useState(false);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [workerCategories, setWorkerCategories] = useState([]);
  const [filtersReady, setFiltersReady] = useState(false);

  const fetchWorkers = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let urlFilters = '';
    if (filters?.selectedSearchBy && query) {
      if (filters?.selectedSearchBy?.id === 'name') {
        if (query.includes(' ')) {
          const [firstName, lastName] = query.split(' ');
          urlFilters += `&name=${firstName}&last_name=${lastName}`;
        } else {
          urlFilters += `&first_or_last_name=${query}`;
        }
      } else {
        urlFilters += `&${filters?.selectedSearchBy?.id}=${query}`;
      }
    }

    if (filters?.activityFilter?.id === 'active' || filters?.activityFilter?.id === 'inactive') {
      urlFilters += `&is_active=${filters?.activityFilter.id === 'active' ? 'true' : 'false'}`;
    }

    if (filters?.departmentFilter) {
      const departmentFilterValue = filters?.departmentFilter;
      urlFilters += `&${customDepartment ? `${customDepartment}` : 'department'}=${customDepartment ? departmentFilterValue.id : departmentFilterValue.name}`;
    }

    if (filters?.companiesFilter) {
      const companiesFilterValue = filters?.companiesFilter;
      urlFilters += `&partner=${companiesFilterValue.id}`;
    }

    if (filters?.workPermit?.id === 'work_permit_needed') urlFilters += `&work_permit=true&work_permit_valid_until_after=${moment().format('YYYY-MM-DD')}`;
    else if (filters?.workPermit?.id === 'work_permit_not_needed') urlFilters += '&work_permit=false';
    else if (filters?.workPermit?.id === 'no_work_permit') urlFilters += `&work_permit=true&work_permit_before_or_none=${moment().format('YYYY-MM-DD')}`;

    if (extraFilters?.relatedInfo && (extraFilters?.relatedInfoValue || extraFilters?.relatedInfoValueQuery)) {
      const infoCode = extraFilters?.relatedInfo?.code;
      const valueType = extraFilters?.relatedInfo?.value_type;

      const infoValue = valueType === 'bool' ? extraFilters?.relatedInfoValue?.id : valueType === 'list' ? extraFilters?.relatedInfoValueQuery : extraFilters?.relatedInfoValue?.data?.value;

      const infoMissing = extraFilters?.relatedInfo?.value_type === 'bool' &&
        extraFilters?.relatedInfoValue?.id === false ? '&info_missing=true' : '';

      urlFilters += `&info=${infoCode}&info_value=${infoValue}${infoMissing}`;
    }

    if (filters?.workerCategory?.length > 0) {
      const workerCategoryIds = filters.workerCategory.map((category) => category.id).join(',');
      urlFilters += `&worker_category__in=${workerCategoryIds}`;
    }

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    if (filters?.selectedSort === 'external_id') urlFilters += `&order_by=${asc}numeric_external_id`;
    else urlFilters += `&order_by=${asc}${filters?.selectedSort}`;

    urlFilters += '&extend_data=true';

    getWorkersList(locationId, companyId, urlFilters)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          count: get(res, 'data.count', null),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoading: false,
        });
      })
      .catch((e) => { setTableData((prevState) => ({ ...prevState, isLoading: false })); console.error('Error while fetching workers', e); });
  };

  const fetchDepartments = async () => {
    if (!can(SECTIONS.DEPARTMENT_MANAGER, ACTIONS.VIEW)) {
      setDepartmentOptions(managerDepartments || []);
    } else {
      const res = await getAllDepartments(locationId);
      setDepartmentOptions(get(res, 'data.results', []));
    }
  };

  const fetchCompanies = async () => {
    const res = await getPartners(companyId);
    setCompaniesOptions(get(res, 'data.results', []));
  };

  const fetchRelatedInfo = async () => {
    await getRelatedInfo(companyId, '&limit=9999&is_active=true')
      .then((resp) => setRelatedInfo(get(resp, 'data.results', [])));
  };

  const fetchRelatedWorkerInfo = async (code) => {
    setIsLoadingRelatedInfoValues(true);
    const resp = await getRelatedWorkerInfo(companyId, `&code=${code}&limit=9999`);
    const response = get(resp, 'data.results', []);

    const uniqueValues = new Set();
    const newRelatedInfoValues = response.filter((info) => {
      const value = info?.data?.value;
      if (!value || uniqueValues.has(value)) return false;
      uniqueValues.add(value);
      return true;
    });

    setIsLoadingRelatedInfoValues(false);
    setRelatedInfoValues(newRelatedInfoValues);
  };

  const fetchWorkerCategories = async () => {
    getWorkforceWorkerCategory(`?company=${companyId}&limit=999&order_by=name`)
      .then((res) => setWorkerCategories(get(res, 'data.results', [])));
  };

  useEffect(() => {
    latestStateRef.current = { extraFilters, filters, query };
  }, [extraFilters, filters, query]);

  useEffect(() => {
    if (extraFilters?.relatedInfo?.value_type === 'bool') setRelatedInfoValues([{ id: true, data: { value: t('page_content.human_resources.workers.worker_related_info_bool_true') } }, { id: false, data: { value: t('page_content.human_resources.workers.worker_related_info_bool_false') } }]);
    if (extraFilters?.relatedInfo?.value_type === 'text') fetchRelatedWorkerInfo(extraFilters?.relatedInfo?.code);
  }, [extraFilters?.relatedInfo]);

  useEffect(() => {
    fetchDepartments();
    fetchCompanies();
    fetchRelatedInfo();
    fetchWorkerCategories();

    return () => {
      const dataToSaveToMe = { ...latestStateRef.current };
      if (currentUser) {
        currentUser.data.HR = dataToSaveToMe;
        setCurrentUser(currentUser);
        saveSortingAndFiltering({ data: currentUser.data });
      }
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      const dataToSaveToMe = { ...latestStateRef.current };
      if (currentUser) {
        currentUser.data.HR = dataToSaveToMe;
        setCurrentUser(currentUser);
        saveSortingAndFiltering({ data: currentUser.data });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const clearAllFilters = () => {
    setQuery('');

    // Define the new filters
    const newFilters = {
      ...filters, // Keep the existing filters
      activityFilter: '',
      departmentFilter: !can(SECTIONS.DEPARTMENT_MANAGER, ACTIONS.VIEW) ? managerDepartments[0] : null,
      companiesFilter: '',
      selectedSearchBy: searchByOptions[0],
      workPermit: null,
      workerCategory: null,
      selectedAscDesc: 'desc',
      selectedSort: 'active_from',
    };

    // Only update filters if they are different
    if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
      setFilters(newFilters);
    }

    // Define the new extra filters
    const newExtraFilters = {
      ...extraFilters, // Keep the existing extra filters
      relatedInfo: null,
      relatedInfoValue: null,
      relatedInfoValueQuery: '',
    };

    // Only update extra filters if they are different
    if (JSON.stringify(extraFilters) !== JSON.stringify(newExtraFilters)) {
      setExtraFilters(newExtraFilters);
    }
  };

  useEffect(() => {
    if (department) {
      clearAllFilters();
      setFilters((prevState) => ({
        ...prevState,
        departmentFilter: !can(SECTIONS.DEPARTMENT_MANAGER, ACTIONS.VIEW) ? managerDepartments[0] : department,
      }));
    }
    setFiltersReady(true);
  }, [department]);

  useEffect(() => {
    if (filtersReady) {
      fetchWorkers();
    }
  }, [filters, filtersReady]);

  useEffect(() => {
    if ((extraFilters?.relatedInfo && (extraFilters?.relatedInfoValue || extraFilters?.relatedInfoValueQuery)) ||
      (!extraFilters?.relatedInfo && (!extraFilters?.relatedInfoValue || !extraFilters?.relatedInfoValueQuery))) {
      fetchWorkers();
    }
  }, [extraFilters]);

  useEffect(() => {
    if (query !== null && query !== get(sortingAndFiltering, 'query', null)) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchWorkers();
      }, 300);
    }
  }, [query]);

  const fetchPaginatedWorkersList = () => {
    if (!tableData.next) return; // Stop if there's no next page

    getPaginatedWorkersList(tableData.next)
      .then((res) => {
        const count = get(res, 'data.count', null);
        const next = get(res, 'data.next', null);
        const previous = get(res, 'data.previous', null);

        setTableData({
          data: [...tableData.data, ...get(res, 'data.results', [])],
          count,
          next,
          previous,
          isLoading: false,
        });
      });
  };

  const redirectToWorkerDetails = (row) => {
    const workerId = row.original.id || null;
    history.push(`/${companyId}/industry/location/${locationId}/human-resources/worker/${workerId}`);
  };

  const transformNameOrSurnameString = (string) => {
    if (!string) {
      return '';
    }
    const deconstructedString = string.split(/\s+|-/);
    const formatedString = deconstructedString.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');

    return formatedString;
  };

  const handleEditButton = (e, row) => {
    e.stopPropagation();
    setShowModal(true);
    setData(row.original);
  };

  const handleClose = () => {
    setShowModal(false);
    setData(null);
  };

  const handleCloseAndRefetch = () => {
    setShowModal(false);
    setData(null);
    fetchWorkers();
  };

  const handleAddNewWorker = () => {
    setShowModal(true);
    setData(null);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleExtraFilterChange = (key, value) => {
    if (!value) {
      setExtraFilters((prevState) => ({
        ...prevState,
        relatedInfo: null,
        relatedInfoValue: null,
        relatedInfoValueQuery: '',
      }));
    } else if (key === 'relatedInfo') {
      setExtraFilters((prevState) => ({
        ...prevState,
        [key]: value,
        relatedInfoValue: null,
        relatedInfoValueQuery: '',
      }));
    } else {
      setExtraFilters((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleSorting = (sortData) => {
    let column = sortData.id;

    if (column === 'location_department.name') column = 'location_department__name';
    if (column === 'department_shift') column = 'department_shift__name';
    if (column === 'workingplaces') column = 'workingplaces__id';
    if (column === 'partner') column = 'partner__name';

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: column,
    }));
  };

  const exportToExcel = () => {
    let urlFilters = '';
    if (filters?.selectedSearchBy && query) {
      if (filters?.selectedSearchBy?.id === 'name') {
        if (query.includes(' ')) {
          const [firstName, lastName] = query.split(' ');
          urlFilters += `&name=${firstName}&last_name=${lastName}`;
        } else {
          urlFilters += `&first_or_last_name=${query}`;
        }
      } else {
        urlFilters += `&${filters?.selectedSearchBy?.id}=${query}`;
      }
    }

    if (filters?.activityFilter?.id === 'active' || filters?.activityFilter?.id === 'inactive') {
      urlFilters += `&is_active=${filters?.activityFilter.id === 'active' ? 'true' : 'false'}`;
    }

    if (filters?.departmentFilter) {
      const departmentFilterValue = filters?.departmentFilter;
      urlFilters += `&${customDepartment ? `${customDepartment}` : 'department'}=${customDepartment ? departmentFilterValue.id : departmentFilterValue.name}`;
    }

    if (filters?.companiesFilter) {
      const companiesFilterValue = filters?.companiesFilter;
      urlFilters += `&partner=${companiesFilterValue.id}`;
    }

    if (filters?.workPermit?.id === 'work_permit_needed') urlFilters += `&work_permit=true&work_permit_valid_until_after=${moment().format('YYYY-MM-DD')}`;
    else if (filters?.workPermit?.id === 'work_permit_not_needed') urlFilters += '&work_permit=false';
    else if (filters?.workPermit?.id === 'no_work_permit') urlFilters += `&work_permit=true&work_permit_before_or_none=${moment().format('YYYY-MM-DD')}`;

    if (extraFilters?.relatedInfo && (extraFilters?.relatedInfoValue || extraFilters?.relatedInfoValueQuery)) {
      const infoCode = extraFilters?.relatedInfo?.code;
      const valueType = extraFilters?.relatedInfo?.value_type;

      const infoValue = valueType === 'bool' ? extraFilters?.relatedInfoValue?.id : valueType === 'list' ? extraFilters?.relatedInfoValueQuery : extraFilters?.relatedInfoValue?.data?.value;

      const infoMissing = extraFilters?.relatedInfo?.value_type === 'bool' &&
        extraFilters?.relatedInfoValue?.id === false ? '&info_missing=true' : '';

      urlFilters += `&info=${infoCode}&info_value=${infoValue}${infoMissing}`;
    }

    if (filters?.workerCategory) {
      urlFilters += `&worker_category=${filters?.workerCategory.id}`;
    }

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    if (filters?.selectedSort === 'external_id') urlFilters += `&order_by=${asc}numeric_external_id`;
    else urlFilters += `&order_by=${asc}${filters?.selectedSort}`;

    urlFilters += '&extend_data=true';

    api.get(`/api/v1/workforce/workers/?company=${companyId}&location=${locationId}${urlFilters}&format=xlsx&limit=${tableData?.count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Workers.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const styledPermitStatus = (status) => {
    const defaultStyle = {
      display: 'inline-block',
      border: '1px solid gray',
      color: '#555',
      borderRadius: '4px',
      padding: '4px 8px',
      width: 'auto',
      minWidth: '75px',
      textAlign: 'center',
    };

    switch (status) {
      case 'not_needed':
        return {
          ...defaultStyle,
          color: '#3cc13b',
          borderColor: '#3cc13b',
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3cc13b',
        };
      case 'missing':
        return {
          ...defaultStyle,
          color: '#dc3545',
          borderColor: '#dc3545',
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #dc3545',
        };
      default:
        return defaultStyle;
    }
  };

  const tableColumnConfig = [
    {
      Header: () => <span>PIN</span>,
      accessor: 'external_id',
      width: 60,
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_first_name')}</span>,
      accessor: 'name',
      width: 100,
      Cell: (row) => (row.value ? transformNameOrSurnameString(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_last_name')}</span>,
      accessor: 'last_name',
      width: 120,
      Cell: (row) => (row.value ? transformNameOrSurnameString(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_email')}</span>,
      accessor: 'email',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_private_email')}</span>,
      accessor: 'private_email',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_department')}</span>,
      accessor: `${customDepartment}.name` || 'department',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.default_shift')}</span>,
      accessor: 'department_shift',
      Cell: (row) => (row.value ? `${row?.value?.name} (${moment(row?.value?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(row?.value?.end_time, 'HH:mm:ss').format('HH:mm')})` : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_working_place')}</span>,
      accessor: 'workingplaces',
      width: 125,
      Cell: (row) => (row?.value?.length ? <ValuesMapper data={row?.value} accessor="working_place.name" sliceNumber={1} /> : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_company')}</span>,
      accessor: 'partner',
      width: 100,
      sortable: false,
      Cell: ({ original }) => (original?.partner?.name ? original.partner.name : original?.worker_category?.name ? original.worker_category.name : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_active_from')}</span>,
      accessor: 'active_from',
      width: 100,
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_active_to')}</span>,
      accessor: 'active_to',
      width: 100,
      Cell: (row) => (row.original?.is_active ? '-' : row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.input_label_work_permit')}</span>,
      accessor: 'work_permit_valid_until',
      width: 120,
      Cell: (row) => (!row.original?.work_permit ?
        <span style={styledPermitStatus('not_needed')}>{t('page_content.human_resources.workers.not_needed')}</span> :
        row.value ?
          moment(row.value).format(defaultDateFormat) :
          <span style={styledPermitStatus('missing')}>{t('page_content.human_resources.workers.missing')}</span>),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_active')}</span>,
      accessor: 'is_active',
      width: 85,
      Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>,
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_edit')}</span>,
      width: 75,
      style: {
        cursor: 'default',
      },
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handleEditButton(e, row)} disabled={!can(SECTIONS.WORKERS_TABS__WORKERS_LIST_TAB, ACTIONS.EDIT)}>
          <IconEdit
            height="14px"
            width="14px"
            fill="#4285F4"
          />
        </Button>
      </div>),
    },
  ];

  const workPermitOptions = [
    { id: 'work_permit_needed', name: t('page_content.human_resources.workers.work_permit_option_yes') },
    { id: 'work_permit_not_needed', name: t('page_content.human_resources.workers.work_permit_option_not_needed') },
    { id: 'no_work_permit', name: t('page_content.human_resources.workers.work_permit_option_no') },
  ];

  return (
    <div className="workersList_container">
      <div className="workersList_addNew">
        <div className="filters">
          <div className="input_container_search">
            <div className="input_field_style">
              <input onChange={(e) => { setQuery(e.target.value); }} placeholder={t('page_content.orders.search_for_placeholder')} value={query || ''} />
              {query && <button onClick={() => { setQuery(''); }}>&times;</button>}
            </div>
            <Select
              className="select-style"
              options={searchByOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={(e) => handleFilterChange('selectedSearchBy', e)}
              value={searchByOptions.find((a) => a.id === filters?.selectedSearchBy?.id) || ''}
              styles={selectModalStyles}
            />
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>

          <div className="filters__related_info">
            <Select
              className="select-style"
              options={relatedInfo}
              getOptionLabel={(option) => t(`page_content.human_resources.workers.worker_related_info_${option.code}`)}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.human_resources.workers.filter_by_related_info_placeholder')}
              isClearable
              onChange={(e) => handleExtraFilterChange('relatedInfo', e)}
              value={extraFilters?.relatedInfo || ''}
              styles={selectModalStyles}
            />
            {
              extraFilters?.relatedInfo?.value_type === 'list' ? (
                <div className="input_field_style">
                  <input
                    onChange={(e) => handleExtraFilterChange('relatedInfoValueQuery', e.target.value)}
                    placeholder={t('page_content.orders.search_for_placeholder')}
                    value={extraFilters?.relatedInfoValueQuery || ''}
                    disabled={!extraFilters?.relatedInfo}
                  />
                  {
                    extraFilters?.relatedInfoValueQuery &&
                    <button onClick={() => handleExtraFilterChange('relatedInfoValueQuery', '')}>&times;</button>
                  }
                </div>
              ) : (
                <Select
                  className="select-style"
                  options={relatedInfoValues}
                  getOptionLabel={(option) => option.data?.value}
                  getOptionValue={(option) => option.id}
                  placeholder={t('page_content.human_resources.workers.filter_by_related_value_info_placeholder')}
                  isLoading={isLoadingRelatedInfoValues}
                  isDisabled={!extraFilters?.relatedInfo}
                  onChange={(e) => handleExtraFilterChange('relatedInfoValue', e)}
                  value={extraFilters?.relatedInfoValue || ''}
                  styles={selectModalStyles}
                />
              )
            }
          </div>
        </div>
        <div className="filters">
          <Select
            className="select-style"
            options={activeFilterOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_activity_placeholder')}
            isClearable
            onChange={(e) => handleFilterChange('activityFilter', e)}
            value={activeFilterOptions.find((a) => a.id === filters?.activityFilter?.id) || ''}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={departmentOptions.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_department_placeholder')}
            isClearable={can(SECTIONS.DEPARTMENT_MANAGER, ACTIONS.VIEW)}
            onChange={(e) => handleFilterChange('departmentFilter', e)}
            value={departmentOptions.find((a) => a.id === filters?.departmentFilter?.id) || ''}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={companiesOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_cooperant_placeholder')}
            isClearable
            isSearchable
            onChange={(e) => handleFilterChange('companiesFilter', e)}
            value={companiesOptions.find((a) => a.id === filters?.companiesFilter?.id) || ''}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={workPermitOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_work_permit_placeholder')}
            isClearable
            onChange={(e) => handleFilterChange('workPermit', e)}
            value={filters?.workPermit || ''}
            styles={selectModalStyles}
          />
          <div style={{ width: 'auto', minWidth: '200px', maxWidth: '400px' }}>
            <Select
              options={workerCategories}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.human_resources.workers.filter_by_worker_category_placeholder', { companyName: company?.name || '-' })}
              isClearable
              isMulti
              onChange={(e) => handleFilterChange('workerCategory', e || '')}
              value={filters?.workerCategory || ''}
              styles={selectModalStyles}
            />
          </div>

          <div className="clear_button"><Button type="plain" onClick={clearAllFilters}>{t('page_content.orders.clear_all_button')}</Button></div>
          <div className="add_worker_button">
            <Button type="add" onClick={handleAddNewWorker} disabled={!can(SECTIONS.WORKERS_TABS__WORKERS_LIST_TAB, ACTIONS.ADD)}>{t('page_content.human_resources.workers.button_add_new_worker')}</Button>
            <Button disabled={!can(SECTIONS.WORKERS_TABS__WORKERS_LIST_TAB, ACTIONS.EXPORT)} type="export" onClick={exportToExcel}>{t('page_content.human_resources.workers.button_export_to_excel')}</Button>
          </div>
        </div>
      </div>
      <div className="workersList_tableArea">
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumnConfig}
          data={tableData.data}
          minRows={0}
          defaultPageSize={30}
          noDataText=" "
          showPagination={false}
          loading={tableData.isLoading}
          defaultSorted={[{ id: filters?.selectedSort, desc: filters?.selectedAscDesc === 'desc' }]}
          onSortedChange={(newSorted) => handleSorting(newSorted[0])}
          getTrProps={(state, rowInfo) => {
            if (rowInfo) {
              const isWorkPermit = get(rowInfo, 'original.work_permit', false);

              const workPermitValidUntil = moment(rowInfo.original.work_permit_valid_until);
              const fortyDaysFromToday = moment().add(40, 'days');
              const isWithinFortyDays = (workPermitValidUntil.isSameOrBefore(fortyDaysFromToday) && !workPermitValidUntil.isAfter(moment())) || workPermitValidUntil.isSameOrBefore(moment());

              return {
                style: { backgroundColor: isWithinFortyDays && isWorkPermit ? 'rgb(239, 195, 195)' : null },
                onClick: () => redirectToWorkerDetails(rowInfo),
              };
            }
          }}
          isInfiniteScroll
          fetchMoreData={fetchPaginatedWorkersList}
          filters={{ ...filters, ...extraFilters, query }}
          stickyHeader
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          count={tableData.count}
          isInfiniteScroll
          data={tableData.data}
          filters={filters}
        />
      </div>

      {
        showModal && (
          <WorkerModalForm
            t={t}
            initialData={data}
            companyId={companyId}
            showModal={showModal}
            locationId={locationId}
            handleClose={handleClose}
            departments={departmentOptions}
            customDepartment={customDepartment}
            companiesOptions={companiesOptions}
            handleCloseAndRefetch={handleCloseAndRefetch}
          />
        )
      }

    </div>
  );
};

WorkersList.propTypes = {
  t: PropTypes.func.isRequired,
  department: PropTypes.number,
  can: PropTypes.func.isRequired,
  customDepartment: PropTypes.string,
  managerDepartments: PropTypes.array,
  history: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    company: get(state, 'app.company', null),
    companyId: get(state, 'app.company.id', null),
    locationId: get(state, 'app.location.id', null),
    customDepartment: get(state, 'app.companyConfig.config.custom_department'),
    managerDepartments: get(state, 'role.managerDepartments', []),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

const mapDispatchToProps = {
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(WorkersList)));
